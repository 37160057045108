#contact {
  scroll-margin-top: 5rem;
}

.contact__container__heading {
  color: var(--color-primary);
  margin: 0 auto 4rem;
  width: fit-content;
  position: relative;
}

.contact__container__heading::after {
  content: '';
  width: 6rem;
  height: 1px;
  position: absolute;
  bottom: 0;
  right: -1rem;
  background: var(--color-primary);
}

.form {
  width: 60%;
  margin: 0 auto;
}
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary);
  resize: none;
  color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
  .form {
    width: 75%;
    margin: 0 auto;
  }
}

/* ========================= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
  .form {
    width: 90%;
    margin: 0 auto;
  }
}
