#projects {
  scroll-margin-top: 8rem;
}
.project__container__heading {
  color: var(--color-primary);
  margin: 0 auto 4rem;
  width: fit-content;
  position: relative;
}

.project__container__heading::after {
  content: '';
  width: 6rem;
  height: 1px;
  position: absolute;
  bottom: 0;
  right: -1rem;
  background: var(--color-primary);
}

.project__card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}
.flip-card {
  height: auto;
  position: relative;
  border: 1px solid var(--color-primary);
  padding: 0.8rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 2px 2px 10px var(--color-primary);
  transition: var(--transition);
  cursor: pointer;
}

.flip-card-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.project__img {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 0.3rem;
}

.flip-card p {
  font-size: 0.8rem;
  margin: 0.5rem 0;
}

.flip-card .status,
.flip-card .type {
  padding: 0.3rem;
}

.yellow {
  border: 1px solid #ff5f15;
  color: #ff5f15;
}

.green {
  border: 1px solid green;
  color: green;
}

.flip-card .type {
  color: var(--color-primary);
}

.flip-card h3 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  color: var(--color-primary);
}
.project__card__description {
  text-align: justify;
  font-size: 1rem;
  color: var(--color-primary);
}
.project__techstack {
  font-size: 0.9rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  color: var(--color-secondary);
}

.project__techstack span {
  border: 1px solid var(--color-secondary-variant);
  border-radius: 0.3rem;
  padding: 0.1rem 0.3rem;
}

@media screen and (max-width: 1024px) {
  /* .project__techstack {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.8rem;
  } */
}

/* ========================= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
  .project__container__heading {
    margin: 0 auto 2rem;
    font-size: 1.2rem;
  }

  .project__container__heading::after {
    content: '';
    width: 4rem;
    height: 1px;
    position: absolute;
    bottom: 0;
    right: -1rem;
    background: var(--color-primary);
  }
}
