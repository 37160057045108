#skills {
  background: var(--color-bg);
  height: auto;
  padding-bottom: 1rem;
  scroll-margin-top: 8rem;
}

.skills {
  padding-top: 1rem;
  color: var(--color-primary);
  margin: 0 auto 1rem;
  width: fit-content;
  position: relative;
}

.skills::after {
  content: '';
  width: 6rem;
  height: 1px;
  position: absolute;
  bottom: 0;
  right: -1rem;
  background: var(--color-primary);
}

.skill {
  color: var(--color-primary);
  text-align: center;
}
.card__title > h2 {
  font-size: 1.1rem;
}

.card {
  display: inline-block;
  background: var(--color-bg);
  padding: 0.6rem 1rem;
  margin: 0.5rem 1rem;
  border-radius: 0.3rem;
}

@media screen and (max-width: 1024px) {
  .skills {
    font-size: 1.5rem;
  }
  .card {
    padding: 0.75rem 1rem;
    margin: 0.5rem 1rem;
  }
  .card__title > h2 {
    font-size: 1rem;
  }
}

/* ========================= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
  #skills {
    margin-top: 0;
    scroll-margin-top: 5rem;
  }
  .skills {
    padding-top: 0.5rem;
    font-size: 1.2rem;
  }
  .card {
    padding: 0.5rem 0.75rem;
    margin: 0.5rem 1rem;
  }
  .card__title > h2 {
    font-size: 0.9rem;
  }
}
