.footer {
  height: 4rem;
  color: var(--color-primary);
}

.footer p {
  text-align: center;
  padding-top: 1.5rem;
  font-size: 0.6rem;
}
