header {
  height: calc(100vh - 5rem);
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.header__title h1 {
  color: var(--color-primary);
}

.header__title h5 {
  color: var(--color-primary-variant);
}
.header {
  height: 30%;
  display: flex;
  flex-direction: column;
  gap: 10rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.hide {
  display: none;
}

.about {
  width: 50%;
  margin: 0 auto;
  text-align: justify;
  color: var(--color-primary-variant);
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  color: var(--color-primary);
}

/* .btn-cv {
  background: tansparent;
  color: var(--color-light);
  border: 2px solid var(--color-bg-variant);
  transition: var(--transition);
} */

/* .btn-cv:hover {
  background: var(--color-light);
  color: var(--color-bg-variant);
} */

.header__socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  letter-spacing: 0.5px;
  font-size: 0.9rem;
}

/* ========================= MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
  .header {
    gap: 3rem;
  }
}

/* ========================= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header {
    width: 100%;
    height: 100%;
    gap: 1rem;
    justify-content: center;
  }

  .header__title > h1 {
    font-size: 2rem;
  }
  .header__title > h5 {
    font-size: 0.8rem;
  }
  .about {
    display: none;
  }
  .hide {
    margin-top: 4rem;
    display: inline-block;
  }

  .header__socials {
    bottom: 5rem;
  }

  .scroll__down {
    bottom: 7rem;
  }
}
