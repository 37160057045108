@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
}

:root {
  /* --color-bg: #011126;
  --color-bg-variant: #3c6373;
  --color-bg-light: #f2f2f2;
  --color-primary: #e3dcf2;
  --color-dark: var(--color-bg);
  --color-white: #fff;
  --color-light: #f2f2f2; */
  /* --color-bg: rgb(242, 242, 242);
  --color-bg-variant: rgba(227, 220, 242, 0.5);
  --color-bg-light: rgba(242, 242, 242, 0.5);
  --color-primary: rgb(1, 17, 38);
  --color-primary-variant: rgba(227, 220, 242, 0.5);
  --color-dark: rgb(60, 99, 115);
  --color-light: #f2f2f2; */
  /* --color-bg: #d8d9d7; */
  /* --color-bg: rgba(136, 57, 239, 0.4);
  --color-nav-bg: #b9c08a;
  --color-gradient-light: #f2f2f2;
  --color-gradient-dark: #7287fd;
  --color-primary: rgb(136, 57, 239);
  --color-primary-variant: #e64553;
  --color-secondary: #dd7878;
  --color-secondary-variant: #dc8a78; */


  --color-bg: rgba(185, 192, 138, 0.4);
  --color-nav-bg: #b9c08a;
  --color-gradient-light: #f2f2f2;
  --color-gradient-dark: #d4d9b4;
  --color-primary: #365902;
  --color-primary-variant: #467302;
  --color-secondary: #74a60a;
  --color-secondary-variant: #add90d;

  /* --color-primary: #401e01;
  --color-primary-variant: #bf6b21;
  --color-secondary: #d9aa55;
  --color-secondary-variant: #f2cda0; */
  /* --color-bg-light: rgba(216, 217, 215, 0.5);
  --color-bg-variant: #3c6373;
  --color-primary: #595302;
  --color-primary-variant: #a69337;
  --color-secondary: #73665f;
  --color-secondary-variant: #a69e76; */
  --transition: all 0.3s ease;
  --container-width-lg: 75%;
  --container-width-md: 85%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  background: radial-gradient(circle,
      var(--color-gradient-light) 0%,
      var(--color-gradient-dark) 100%);
  line-height: 1.7;
}

/* =================== GENERAL ==================== */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-secondary);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-primary);
  color: var(--color-nav-bg);
  border-color: transparent;
}

.btn-second {
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  background: transparent;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  transition: var(--transition);
}

.btn-second:hover {
  background: var(--color-primary);
  color: #fff;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ================= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section>h2 {
    margin-bottom: 2rem;
  }
}
