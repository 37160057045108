.nav__fill {
  background-color: var(--color-nav-bg);
  position: fixed;
  width: 100%;
  top: 0;
  height: 5rem;
  z-index: 99999999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.nav__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.nav__links {
  display: flex;
  gap: 1.6rem;
}

.logo {
  font-size: 2rem;
  font-weight: 500;
}

/* ========================= MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
}

/* ========================= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
  .nav {
    font-size: 0.8rem;
    gap: 0.8rem;
  }
  .cv {
    display: none;
  }
}
