#experience {
  scroll-margin-top: 8rem;
}
.experience__title {
  color: var(--color-primary);
  margin: 0 auto 4rem;
  width: fit-content;
  position: relative;
}

.experience__title::after {
  content: '';
  width: 6rem;
  height: 1px;
  position: absolute;
  bottom: 0;
  right: -1rem;
  background: var(--color-primary);
}

.experience__card {
  width: 70%;
  margin: auto;
  padding: 1rem 1.5rem 2rem;
  border-left: 1px solid var(--color-primary);
}

.experience-title {
  display: flex;
  justify-content: space-between;
}

.experience-title h3 {
  font-weight: 600;
  color: var(--color-primary);
}

.experience-title p {
  color: var(--color-primary-variant);
}

.company__name {
  color: var(--color-secondary);
}

.company__name span {
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.experience__details {
  color: var(--color-primary);
  margin-left: 1rem;
}
.experience__detail__item {
  list-style-type: circle;
}

.experience__techstack {
  margin-top: 1.5rem;
  color: var(--color-primary-variant);

  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.experience__techstack span {
  background: var(--color-bg);
  padding: 0.1rem 0.5rem;
  border-radius: 0.3rem;
}

@media screen and (max-width: 1024px) {
}

/* ========================= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
  #experience {
    scroll-margin-top: 6rem;
  }
  .experience__title {
    margin: 0 auto 2rem;
    font-size: 1.2rem;
  }

  .experience-title {
    flex-direction: column-reverse;
    gap: 0.5rem;
  }
  .experience__techstack {
    flex-wrap: wrap;
  }
  .experience__card {
    width: 95%;
  }

  .experience__card h3 {
    font-size: 1rem;
  }

  .experience__card p {
    font-size: 0.7rem;
  }
  .experience__card h4 {
    font-size: 0.8rem;
    padding-bottom: 1rem;
  }
  /* .details__list {
    font-size: 0.8rem;
  } */
  .experience__techstack {
    font-size: 0.7rem;
  }
}
